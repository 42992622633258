<template>
  <v-app-bar app>
    <v-toolbar-title>{{ title }}</v-toolbar-title>

    <v-spacer></v-spacer>

    <user-log-out-menu />
  </v-app-bar>
</template>

<script>
export default {
  components: {
    "user-log-out-menu": () => import("./UserLogOutMenu.vue"),
  },
  props: ["title"],
};
</script>

<style scoped></style>
